import useSearch from "@/Hooks/useSearch";
import { Link } from "@inertiajs/react";
import debounce from "lodash.debounce";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useMemo } from "react";
import { CiSearch } from "react-icons/ci";
import route from "ziggy-js";
import { IoCloseOutline } from "react-icons/io5";

export function FilterSearchBar({ query, handleChangeQuery, handleResetSearch }) {
    const debouncedResults = useMemo(() => {
        return debounce(handleChangeQuery, 300);
    }, []);

    useEffect(() => {
        return () => {
            debouncedResults.cancel();
        };
    });

    return (
        <form onSubmit={(e) => {
            e.preventDefault();
        }}>
            <div className="relative flex items-center w-full lg:w-[350px] lg:max-w-[350px]">
                <span className="absolute left-3">
                    <CiSearch className="w-[20px] h-[20px]" />
                </span>
                <input
                    type="text"
                    id="filter-search"
                    className="border border-[#E5E7EB] rounded-lg bg-neutral-100 text-[15px] font-medium w-full px-11 py-[14px] h-[46px]"
                    placeholder="Search"
                    onChange={debouncedResults}
                // value={query}
                // onChange={handleChangeQuery}
                />

                {/* {query.length > 0 && (
                    <button type="reset" className="absolute right-3 hover:cursor-pointer" onClick={() => {
                        document.getElementById('filter-search').value = '';
                        handleResetSearch();
                    }}>
                        <IoCloseOutline className="w-[20px] h-[20px]" />
                    </button>
                )} */}

            </div>
        </form>
    );
}
