import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';

export default function DropdownFilter({
    options,
    optionLabel,
    className = "",
    ...props
}) {
    let btnClasses = "w-full border border-[#E5E7EB] lg:min-w-[173px] lg:w-[173px] lg:max-w-[173px] rounded-[8px]"

    const dropdownFilterTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <div className="font-semibold text-[15px] max-w-[110px] overflow-hidden text-ellipsis">{option.name}</div>
                </div>
            );
        }

        return <span className="font-semibold text-[15px]">{props.placeholder}</span>;
    };

    return (
        <Dropdown
            options={options}
            optionLabel={optionLabel}
            filter
            showClear
            className={btnClasses + " " + className}
            valueTemplate={dropdownFilterTemplate}
            pt={{
                input: ({ props }) => ({
                    className: classNames(
                        'cursor-pointer block flex flex-auto overflow-hidden overflow-ellipsis whitespace-nowrap relative',
                        'bg-transparent border-0 text-black',
                        // 'dark:text-white/80',
                        'p-3 transition duration-200 bg-transparent rounded appearance-none font-sans text-base',
                        'focus:outline-none focus:shadow-none',
                        { 'pr-7': props.showClear }
                    )
                }),
                trigger: {
                    className: classNames('flex items-center justify-center shrink-0', 'bg-transparent text-black w-8 rounded-tr-lg rounded-br-lg')
                }
            }}
            {...props}
        />
    );
}
